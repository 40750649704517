/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { NewFileInput } from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import { updateCacheOrder } from "../../../caches/updateCacheOrder"
import FormOrder from "../FormOrder/FormOrder"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { uploadFiles } from "../../../utils/uploadFiles"
import { MinTypeFile } from "../../Common/CustomUploadFiles/CustomUploadFiles"
import { Moment } from "moment"

interface Order {
  message: string
  deadline: Moment | null
  price: string
  craftmanCustomPrice: string
  ceedowCommissionCustomPrice: string
  transporterCustomPrice: string
  quantity: string
}

// CreateOrder mutation
const addOrderMutation = graphql(`
  mutation CreateNewOrder(
    $photos: [NewFileInput!]!
    $price: Float!
    $categoryId: Float!
    $materialId: Float!
    $itemId: Float!
    $customerId: Float!
    $craftmanId: Float!
    $deadline: DateTimeISO
    $countryId: Float
    $craftmanCustomPrice: Float
    $ceedowCommissionCustomPrice: Float
    $transporterCustomPrice: Float
    $quantity: Float
    $message: String!
  ) {
    createNewOrder(
      photos: $photos
      price: $price
      categoryId: $categoryId
      materialId: $materialId
      itemId: $itemId
      customerId: $customerId
      craftmanId: $craftmanId
      deadline: $deadline
      countryId: $countryId
      message: $message
      craftmanCustomPrice: $craftmanCustomPrice
      ceedowCommissionCustomPrice: $ceedowCommissionCustomPrice
      transporterCustomPrice: $transporterCustomPrice
      quantity: $quantity
    ) {
      id
      deadline
      status
      createdAt
      updatedAt
      craftmanCustomPrice
      transporterCustomPrice
      ceedowCommissionCustomPrice
      quantity
      Customer {
        id
        email
        name
        lastName
      }
      Craftman {
        id
        email
        name
        lastName
      }
      Country {
        id
        name_fr
        currency
      }
      Categories {
        id
        name_fr
      }
      ItemTypes {
        id
        name_fr
      }
      MaterialTypes {
        id
        name_fr
      }
      Photos {
        id
        name
        url
      }
    }
  }
`)

export default function AddOrder() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [createorder, { loading }] = useMutation(addOrderMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState<File[]>([])

  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }

  const [category, setCategory] = useState<InfinityListItem>(defaultValue)

  const [craftman, setCraftman] = useState<InfinityListItem>(defaultValue)
  const [customer, setCustomer] = useState<InfinityListItem>(defaultValue)

  const [country, setCountry] = useState<InfinityListItem>(defaultValue)
  const [itemType, setItemType] = useState<InfinityListItem>(defaultValue)
  const [materialType, setMaterialType] =
    useState<InfinityListItem>(defaultValue)

  const [order, setOrder] = useState<Order>({
    message: "",
    deadline: null,
    price: "",
    craftmanCustomPrice: "",
    transporterCustomPrice: "",
    ceedowCommissionCustomPrice: "",
    quantity: "1",
  })

  const handleInputChange = (event: any) => {
    setOrder({
      ...order,
      [event.target.name]: event.target.value,
    })
  }

  const handleInputChangeDeadline = (value: Moment | null) => {
    setOrder({
      ...order,
      deadline: value,
    })
  }

  const handleCreateOrder = ({ photos }: { photos: NewFileInput[] }) => {
    createorder({
      variables: {
        deadline: order.deadline ? order.deadline.toDate() : undefined,
        price: order.price ? Number(order.price) : 0,
        photos,
        message: order.message,
        categoryId: Number(category.value),
        // countryId: Number(country.value),
        itemId: Number(itemType.value),
        materialId: Number(materialType.value),
        craftmanId: Number(craftman.value),
        customerId: Number(customer.value),
        craftmanCustomPrice: order.craftmanCustomPrice
          ? Number(order.craftmanCustomPrice)
          : undefined,
        ceedowCommissionCustomPrice: order.ceedowCommissionCustomPrice
          ? Number(order.ceedowCommissionCustomPrice)
          : undefined,
        transporterCustomPrice: order.transporterCustomPrice
          ? Number(order.transporterCustomPrice)
          : undefined,
        quantity: order.quantity ? Math.abs(parseInt(order.quantity)) : 1,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message, status: "error" }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        window.location.replace("/order/list")
      },
      update: (cache, { data }) => {
        updateCacheOrder({
          action: "add",
          cache,
          entryData: {
            ...data?.createNewOrder,
            __typename: "Order",
          },
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!category.value) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez choisir la catégorie de la commande !!!",
          open: true,
          status: "error",
        }),
      )
      return
    }
    if (!files?.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez choisir les modèles photos de la commande !!!",
          open: true,
          status: "error",
        }),
      )
      return
    }
    if (!itemType.value) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir le(s) type(s) article(s) de la commande !!!",
          open: true,
          status: "error",
        }),
      )
      return
    }
    if (!materialType.value) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir le(s) type(s) matière(s) de la commande !!!",
          open: true,
          status: "error",
        }),
      )
      return
    }
    setIsLoading(true)
    uploadFiles({
      files: files,
      cbErr(err) {
        dispatch(
          setOpenSnackbar({
            message:
              "Veuillez choisir des fichiers plus léger, s'il vous plait",
            open: true,
            status: "error",
          }),
        )
        setIsLoading(false)
      },
    }).then((files) => {
      if (files) {
        handleCreateOrder({
          photos: files,
        })
      }
    })
  }

  const onFilesUpload = (files: File[]) => {
    setFiles(files)
  }

  const onClearUploads = (file: MinTypeFile) => {
    setFiles((prev) => [...prev.filter((_) => _.name !== file.name)])
  }

  const getCategory = (category: InfinityListItem) => {
    setCategory(category)
  }

  const getCraftMan = (craftman: InfinityListItem) => {
    setCraftman(craftman)
  }

  const getCustomer = (customer: InfinityListItem) => {
    setCustomer(customer)
  }

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  const getItemTypes = (itemType: InfinityListItem[] | InfinityListItem) => {
    if (itemType && !Array.isArray(itemType)) {
      setItemType(itemType)
    }
  }

  const getMaterialTypes = (
    materialType: InfinityListItem[] | InfinityListItem,
  ) => {
    if (materialType && !Array.isArray(materialType)) {
      setMaterialType(materialType)
    }
  }

  useEffect(() => {
    setMaterialType(defaultValue)
    setItemType(defaultValue)
  }, [category, craftman])

  useEffect(() => {
    setCategory(defaultValue)
  }, [craftman])

  return (
    <FormOrder
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={isLoading || loading}
      handleInputChangeDeadline={handleInputChangeDeadline}
      title={"Ajouter une commande"}
      categoryDefault={category}
      countryDefault={country}
      getCategory={getCategory}
      getCountry={getCountry}
      getItemTypes={getItemTypes}
      getMaterialTypes={getMaterialTypes}
      itemTypesDefault={itemType}
      materialTypesDefault={materialType}
      categoryId={category.value ? parseInt(category.value) : undefined}
      onClearUploads={onClearUploads}
      onFilesUpload={onFilesUpload}
      required={false}
      message={order.message}
      price={order.price}
      isOffer={!craftman.value}
      deadline={order.deadline}
      isCreated={true}
      craftmanDefault={craftman}
      customerDefault={customer}
      getCraftMan={getCraftMan}
      getCustomer={getCustomer}
      craftmanCustomPrice={order.craftmanCustomPrice}
      ceedowCommissionCustomPrice={order.ceedowCommissionCustomPrice}
      transporterCustomPrice={order.transporterCustomPrice}
      quantity={order.quantity}
    />
  )
}
